import { useWizardContext } from '@/components/wizard';
import * as React from 'react';
import { REVIEW_STEP, SELECT_DATE_STEP, SET_ACCOUNT_STEP, SET_AMOUNT_STEP } from '../constants';

type Payment = {
  paymentAccountID: string;
  amount: string;
  date: string;
  paymentType: string;
  dateType: string;
};

export function useAdditionPayment(props: { onClose: () => void }) {
  const [payment, setPayment] = React.useState<Payment>({
    amount: '',
    date: '',
    paymentType: '',
    paymentAccountID: '',
    dateType: '',
  });
  const wizardController = useWizardContext();

  function setPaymentType(data: { paymentType: string; amount: string }) {
    setPayment({ ...payment, ...data });
    wizardController.goToStep(SET_ACCOUNT_STEP);
  }

  function setPaymentAccount(data: { paymentAccountID: string }) {
    setPayment({ ...payment, ...data });
    wizardController.goToStep(SELECT_DATE_STEP);
  }

  function setPaymentDate(data: { date: string; dateType: string }) {
    setPayment({ ...payment, ...data });
    wizardController.goToStep(REVIEW_STEP);
  }

  function makeAnotherPayment() {
    setPayment({
      amount: '',
      date: '',
      paymentType: '',
      paymentAccountID: '',
      dateType: '',
    });
    wizardController.goToStep(SET_AMOUNT_STEP);
  }

  return {
    payment,
    setPaymentType,
    setPaymentAccount,
    setPaymentDate,
    makeAnotherPayment,
    wizardController,
    onClose: props.onClose,
  };
}
