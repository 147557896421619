import * as React from 'react';
import { Resolver } from 'react-hook-form';
import { validateCustomAmount, validatePaymentType } from '../helpers';

export function useGetAmountAndPaymentTypeResolver(currentBalance: string): Resolver<{
  amount: string;
  paymentType: string;
}> {
  return React.useCallback(
    (values) => {
      const errAmount = validateCustomAmount(values.amount, currentBalance, values.paymentType);
      const errPayment = validatePaymentType(values.paymentType);

      return {
        values,
        errors: { ...errAmount, ...errPayment },
      };
    },
    [currentBalance],
  );
}
