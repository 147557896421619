export function AdditionPaymentSkeleton() {
  return (
    <div className="flex flex-col gap-8 p-12 sm:p-14 mt-8 sm:mt-0 animate-pulse">
      <div className="flex w-14 h-4 bg-gray-200 rounded-lg" />
      <div className="flex w-32 h-4 bg-gray-200 rounded-lg" />
      <div className="flex w-32 h-4 bg-gray-200 rounded-lg" />
      <div className="flex w-80 h-4 bg-gray-200 rounded-lg" />
      <div className="flex w-full h-4 bg-gray-200 rounded-lg" />
      <div className="flex w-full h-4 bg-gray-200 rounded-lg" />
      <div className="flex w-32 h-4 bg-gray-200 rounded-lg" />
      <div className="flex w-32 h-4 bg-gray-200 rounded-lg" />
    </div>
  );
}
