import { ACCOUNT_MANAGE_LINKED_ACCOUNTS_PATH, CREDIT_PAYMENT_PATH } from '@/constants/routes';

export const BACK_TO_PARAM_NAME = 'backTo';

const ALLOWED_REDIRECTION = {
  creditcardpayments: CREDIT_PAYMENT_PATH,
  linkedaccounts: ACCOUNT_MANAGE_LINKED_ACCOUNTS_PATH,
};

export type AllowedRedirection = keyof typeof ALLOWED_REDIRECTION;

export function isValidRedirectToPath(reference: AllowedRedirection | string) {
  return ALLOWED_REDIRECTION.hasOwnProperty(reference);
}

export function getRedirectToPath(reference: AllowedRedirection | string) {
  return ALLOWED_REDIRECTION[reference] ?? ALLOWED_REDIRECTION.linkedaccounts;
}
