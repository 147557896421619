import { UTCDateLongFormatter } from '@/i18n/datetime';
import { CurrencyFormatter } from '@/i18n/numbers';
import { CurrentBalance, MinimumDue, StatementBalance } from '../components/balances-view';

import cx from 'clsx';
import {
  PAYMENT_TYPE_CURRENT_BALANCE,
  PAYMENT_TYPE_CUSTOM_AMOUNT,
  PAYMENT_TYPE_MINIMUM,
  PAYMENT_TYPE_REMAINING_BALANCE,
} from '../constants';

const ZERO_BALANCE = '0.00';

export const paymentTypeOptions = (
  { statementBalance, currentBalance, minimumDue, remainingStatementBalance },
  currentCycleDate,
  dueDate,
  showMoreInfo,
  totalCycleToDatePayment,
) => {
  const hasPostedPayment = parseFloat(totalCycleToDatePayment) > 0;
  const hasNoCurrentBalance = parseFloat(currentBalance) <= 0;
  const isStatementBalanceDisabled = statementBalance === ZERO_BALANCE;

  return [
    {
      label: (
        <div className="mb-md max-w-sm">
          <h6
            data-testid={'remaining_statement_balance_text_wrapper'}
            className={cx('font-normal leading-3 mb-sm', {
              'text-gray': isStatementBalanceDisabled,
            })}
          >
            <div className="mb-sm mt-xs font-medium">
              <span>Remaining Statement Balance</span>
              <span> {CurrencyFormatter.format(parseFloat(remainingStatementBalance))}</span>
            </div>
          </h6>
          <div
            className={cx('mt-xs text-sm font-light leading-4', {
              'text-gray': remainingStatementBalance === ZERO_BALANCE,
            })}
          >
            {showMoreInfo ? (
              <StatementBalance
                currentCycleDate={currentCycleDate}
                dueDate={dueDate}
                isDisabled={isStatementBalanceDisabled}
              />
            ) : (
              !isStatementBalanceDisabled &&
              currentCycleDate && (
                <>
                  <span>
                    This is the amount you owe from your most recent statement dated{' '}
                    {UTCDateLongFormatter.format(new Date(currentCycleDate))} less any payments or credits to your
                    account since then.
                  </span>
                </>
              )
            )}
          </div>
        </div>
      ),
      value: PAYMENT_TYPE_REMAINING_BALANCE,
      disabled: isStatementBalanceDisabled,
    },
    {
      label: (
        <div className="mb-md max-w-sm">
          <h6
            data-testid={'CURRENT_BALANCE_text_wrapper'}
            className={cx('leading-3 mb-sm', {
              'text-gray': hasNoCurrentBalance,
            })}
          >
            <div className="mb-sm mt-xs font-medium">
              <span>Current Balance</span>{' '}
              <span>
                {CurrencyFormatter.format(parseFloat(currentBalance))}
                <sup>*</sup>
              </span>
            </div>
            {showMoreInfo && (
              <span className="font-light">
                <CurrentBalance isDisabled={hasNoCurrentBalance} />
              </span>
            )}
          </h6>
        </div>
      ),
      value: PAYMENT_TYPE_CURRENT_BALANCE,
      disabled: hasNoCurrentBalance,
    },
    {
      label: (
        <div className="mb-md max-w-sm">
          <h6
            data-testid={'minimum_due_text_wrapper'}
            className={cx('font-normal leading-3 mb-sm', {
              'text-gray': minimumDue === ZERO_BALANCE,
            })}
          >
            <div className="mb-sm mt-xs font-medium">
              <span>{hasPostedPayment ? 'Remaining Minimum Due' : 'Minimum Due'}</span>{' '}
              <span>{CurrencyFormatter.format(parseFloat(minimumDue))}</span>
            </div>
            {showMoreInfo && (
              <span className="font-light">
                <MinimumDue isDisabled={minimumDue === ZERO_BALANCE} />
              </span>
            )}
          </h6>
        </div>
      ),
      value: PAYMENT_TYPE_MINIMUM,
      disabled: minimumDue === ZERO_BALANCE,
    },
    {
      label: (
        <div
          data-testid={'CUSTOM_AMOUNT_text_wrapper'}
          className={cx('max-w-sm mb-md', {
            'text-gray': hasNoCurrentBalance,
          })}
        >
          <h6 className="mt-xs mb-sm font-medium leading-3">Custom Amount</h6>
        </div>
      ),
      value: PAYMENT_TYPE_CUSTOM_AMOUNT,
      disabled: hasNoCurrentBalance,
    },
  ];
};
