import { toAmountInCents } from '@/helpers/currency';
import { PAYMENT_TYPE } from '../constants';

export function validatePaymentType(paymentType: string) {
  if (paymentType) {
    return;
  }

  return { paymentType: { type: 'required', message: 'Payment selection required' } };
}

export function validateCustomAmount(amount: string, currentBalance: string, paymentType: string) {
  if (paymentType !== PAYMENT_TYPE.CUSTOM_AMOUNT) return;

  const cleanedAmount = toAmountInCents(amount);
  const cleanedCurrentBalance = toAmountInCents(currentBalance);

  if (cleanedCurrentBalance < cleanedAmount) {
    return { amount: { type: 'required', message: 'Payment amount cannot be greater than your current balance' } };
  }

  if (!amount || cleanedAmount <= 0 || amount === '-') {
    return { amount: { type: 'required', message: 'Payments must be greater than $0.00' } };
  }

  return undefined;
}
