import { SubmitButton } from '@/components/buttons-main/submit-button';
import { ControlledDatePicker } from '@/components/form/components/form-inputs/controlled-date-picker';
import { RadioField } from '@/components/form/components/form-inputs/radio-field';
import { FULLSTORY_UNMASK } from '@/constants';
import { EARLIEST_PAYMENT_DATE, IS_AFTER } from '@/helpers/datetime';
import { UTCDateLongFormatter } from '@/i18n/datetime';
import { CurrencyFormatter } from '@/i18n/numbers';
import clsx from 'clsx';
import * as React from 'react';
import { FUTURE_PAYMENT, IMMEDIATE_PAYMENT } from '../constants';
import { useSelectDate } from '../hooks/use-select-date';
import { AdditionPaymentSkeleton } from './addition-payment-skeleton';

const DATE_OPTIONS = (options: { isTodayPaymentDisabled: boolean; isTodayPaymentSelected: boolean }) => [
  {
    label: options.isTodayPaymentSelected ? (
      <div className="flex flex-col gap-1">
        <p className="text-base">As soon as possible</p>
        <p className="text-sm font-normal">
          Payments made before 5:30 PM Eastern Time will be processed the same day. Payments made after the cutoff time
          will be processed tomorrow.
        </p>
      </div>
    ) : (
      'As soon as possible'
    ),
    value: IMMEDIATE_PAYMENT,
    disabled: options.isTodayPaymentDisabled,
  },
  {
    label: 'Future date',
    value: FUTURE_PAYMENT,
  },
];

export function SelectDate() {
  const controller = useSelectDate();

  if (controller.isLoading) {
    return <AdditionPaymentSkeleton />;
  }

  return (
    <div className={clsx('flex justify-center sm:justify-start flex-col gap-3 p-lg', FULLSTORY_UNMASK)}>
      <p className="text-lg">Credit Card Payment</p>
      <div className="flex gap-2 text-sm">
        <p className="text-blue uppercase">Statement due date</p>
        <span className="text-right flex-1 pr-16">
          {controller.hasPaymentDue
            ? UTCDateLongFormatter.format(new Date(controller.nextPaymentDueDate))
            : 'No payment is due'}
        </span>
      </div>
      <div className="flex gap-2 text-sm">
        <p className="text-blue uppercase">Payment amount</p>
        <span className="text-right flex-1 pr-16">
          {CurrencyFormatter.format(parseFloat(controller.payment.amount))}
        </span>
      </div>
      <div className="flex gap-2 text-sm">
        <p className="text-blue uppercase">Payment from</p>
        <span className="text-right flex-1 pr-16">{controller.accountName}</span>
      </div>
      <hr />

      <p>Select payment date:</p>
      {IS_AFTER && (
        <p className="text-sm font-light max-w-xs">
          After the payment cutoff time of 5:30 PM Eastern time, payments must be scheduled for a future date.
        </p>
      )}

      <form className="flex flex-col gap-3" onSubmit={controller.onSubmit}>
        <RadioField
          {...controller.form.register('dateType')}
          defaultCheckedOption={controller.form.watch().dateType}
          options={DATE_OPTIONS(controller.optionsProps)}
          error={controller.form.formState.errors?.dateType?.message}
        />

        {controller.isShowDatePicker && (
          <ControlledDatePicker
            name="date"
            label="Payment Date"
            placeholder="Payment Date"
            minDate={EARLIEST_PAYMENT_DATE}
            value={controller.form.watch().date || null}
            control={controller.form.control}
            error={controller.form.formState.errors?.date?.message}
          />
        )}

        <div className="flex flex-col gap-2">
          <SubmitButton
            color="blue"
            className="text-md rounded-md m-0 w-full max-w-full disabled:bg-blue-dark/60"
            label="Next"
            disabled={!controller.form.formState.isValid}
          />

          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent w-full bg-grey-lighter  px-4 py-2 text-md font-medium hover:bg-grey-lighter/90 focus:outline-none focus-visible:ring-2 focus-visible:bg-grey-lighter/90 focus-visible:ring-offset-2"
            onClick={controller.onBack}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
}
