import { SubmitButton } from '@/components/buttons-main/submit-button';
import { FULLSTORY_UNMASK } from '@/constants';
import { FAQ_AVAILABLE_CREDIT, HELP_DESK } from '@/constants/app';
import { IS_AFTER } from '@/helpers/datetime';
import { UTCDateShortFormatter } from '@/i18n/datetime';
import { CurrencyFormatter } from '@/i18n/numbers';
import clsx from 'clsx';
import * as React from 'react';
import { useReviewAndSubmit } from '../hooks/use-review-and-submit';
import { AdditionPaymentSkeleton } from './addition-payment-skeleton';

type ContainerProps = {
  date: string;
  amount: string;
  accountName: string;
  isExternal: boolean;
  title: string;
  isFuturePayment: boolean;
};

function Container(props: React.PropsWithChildren<ContainerProps>) {
  return (
    <div className={clsx('flex justify-center sm:justify-start flex-col gap-3 p-6', FULLSTORY_UNMASK)}>
      <p className="text-lg">Credit Card Payment</p>
      <p className="text-md">{props.title}</p>
      <div className="grid grid-cols-2 gap-4 text-sm">
        <div className="flex flex-col">
          <p className="uppercase font-bold">Payment scheduled for</p>
          {props.isFuturePayment ? (
            <span className="font-normal text-base">{UTCDateShortFormatter.format(new Date(props.date))}</span>
          ) : (
            <span className="font-normal text-base">
              {IS_AFTER
                ? UTCDateShortFormatter.format(new Date().setDate(new Date(props.date).getDate() + 1))
                : UTCDateShortFormatter.format(new Date(props.date))}
            </span>
          )}
        </div>
        <div className="flex flex-col">
          <p className="uppercase font-bold">Payment amount</p>
          <span className="font-normal text-base">{CurrencyFormatter.format(parseFloat(props.amount))}</span>
        </div>
        <div className="flex flex-col col-span-2">
          <p className="uppercase font-bold">Pay from</p>
          <span className="font-normal text-base">{props.accountName}</span>
        </div>
      </div>
      <hr />
      {props.isExternal ? (
        <p className="text-tiny font-light">
          Payments will be processed typically within (6) business days and will be posted as of the day they are
          scheduled for.{' '}
          <a href={FAQ_AVAILABLE_CREDIT} target="_blank" rel="noopener noreferrer" className="text-blue font-medium">
            Learn more here.
          </a>
        </p>
      ) : (
        <p className="text-tiny font-light">
          Payments will be processed overnight on business days and will be posted on the scheduled date. Your available
          credit will reflect your payment on the day the payment is made.{' '}
          <a href={FAQ_AVAILABLE_CREDIT} target="_blank" rel="noopener noreferrer" className="text-blue font-medium">
            Learn more here.
          </a>
        </p>
      )}
      <div className="flex flex-col gap-2">{props.children}</div>
    </div>
  );
}

export function ReviewAndSubmit() {
  const controller = useReviewAndSubmit();

  if (controller.isLoadingAccounts) {
    return <AdditionPaymentSkeleton />;
  }

  if (controller.isError) {
    return (
      <div className={clsx('flex justify-center sm:justify-start flex-col gap-3 p-6', FULLSTORY_UNMASK)}>
        <p className="text-lg">Credit Card Payment</p>
        <p className="text-md">Payment failed.</p>
        <p className="text-base font-normal">
          There was a problem scheduling your payment. We apologize for the inconvenience.
        </p>
        <p className="text-red text-sm">{controller.errorMessage}</p>
        <div className="flex flex-col gap-2">
          <SubmitButton
            color="blue"
            className="text-md rounded-md m-0 w-full max-w-full disabled:bg-blue-dark/60"
            label="Try again"
            onClick={controller.onMakeAnotherPayment}
          />
          <a
            href={HELP_DESK}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex justify-center items-center rounded-md w-full bg-grey-lighter px-4 py-2.5 text-md font-medium hover:bg-grey-lighter/90 focus:outline-none focus-visible:ring-2 focus-visible:bg-grey-lighter/90 focus-visible:ring-offset-2"
          >
            Contact support
          </a>
        </div>
      </div>
    );
  }

  if (controller.isSuccess) {
    return (
      <Container
        title="Payment successfully scheduled!"
        date={controller.payment.date}
        amount={controller.payment.amount}
        isExternal={controller.isExternal}
        isFuturePayment={controller.isFuturePayment}
        accountName={controller.accountName}
      >
        <p className="text-tiny font-light">
          Payments may be canceled up until 5:30 PM Eastern Time of the scheduled date.
        </p>
        <SubmitButton
          color="blue"
          className="text-md rounded-md m-0 w-full max-w-full disabled:bg-blue-dark/60"
          label="Done"
          onClick={controller.onClose}
        />
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent w-full bg-grey-lighter px-4 py-2 text-md font-medium hover:bg-grey-lighter/90 focus:outline-none focus-visible:ring-2 focus-visible:bg-grey-lighter/90 focus-visible:ring-offset-2"
          onClick={controller.onMakeAnotherPayment}
        >
          Make another payment
        </button>
      </Container>
    );
  }

  return (
    <Container
      title="Review and Submit"
      date={controller.payment.date}
      amount={controller.payment.amount}
      isExternal={controller.isExternal}
      isFuturePayment={controller.isFuturePayment}
      accountName={controller.accountName}
    >
      <SubmitButton
        color="blue"
        className="text-md rounded-md m-0 w-full max-w-full disabled:bg-blue-dark/60"
        label="Submit"
        loading={controller.isSubmitting}
        onClick={controller.onSubmit}
      />

      <button
        type="button"
        className="inline-flex justify-center rounded-md border border-transparent w-full bg-grey-lighter  px-4 py-2 text-md font-medium hover:bg-grey-lighter/90 focus:outline-none focus-visible:ring-2 focus-visible:bg-grey-lighter/90 focus-visible:ring-offset-2"
        onClick={controller.onBack}
      >
        Back
      </button>
    </Container>
  );
}
