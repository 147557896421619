import { CreditAccountContext } from '@/contexts/credit-account-context';
import { useContext } from 'react';

export function useCreditAccountContext() {
  return useContext(CreditAccountContext);
}

export function useActiveCreditAccountContext() {
  const activeCredit = useCreditAccountContext();

  if (!activeCredit.hasAccount) {
    // redirect here
    throw new Error('Credit account is not active');
  }

  return activeCredit;
}
