import cx from 'clsx';
import * as React from 'react';
import { mergeRefs } from '../helpers/merge-refs';

export interface RadioProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
  label?: string | any;
  hasError?: boolean;
  className?: string;
  disabled?: boolean;
  labelClassName?: string;
  labelBottomSection?: string | React.ReactNode;
}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(function Radio(props, ref) {
  const { className, labelClassName = '', label = '', hasError, labelBottomSection, ...radioProps } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [focus, setFocus] = React.useState(false);
  return (
    <label
      className={cx('cursor-pointer flex align-middle mb-2', className, {
        'border-red': hasError,
      })}
    >
      <div className="cursor-pointer flex align-middle">
        <input
          className="opacity-0 w-0 h-0"
          type="radio"
          data-testid={`radio-${radioProps.value}`}
          {...radioProps}
          ref={mergeRefs<HTMLInputElement>(ref, inputRef)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          tabIndex={0}
        />
        <div
          className={cx('relative w-lg h-lg min-w-[24px] rounded-full border bg-white', {
            'border-blue-dark hover:border-blue-dark hover:after:bg-blue-dark after:absolute after:rounded-full after:border after:border-white after:bg-blue-dark after:top-px after:right-px after:left-px after:bottom-px':
              focus,
            'border-blue hover:border-blue-dark hover:after:bg-blue-dark after:absolute after:rounded-full after:border after:border-white after:bg-blue after:top-px after:right-px after:left-px after:bottom-px':
              radioProps.checked && !focus,
            'border-black-lighter hover:border-blue': !radioProps.checked,
            'border-black-inactive after:bg-black-inactive': radioProps.disabled,
          })}
        />
        <div
          className={cx('text-base leading-normal', labelClassName, {
            'text-black-inactive': inputRef.current?.disabled,
          })}
        >
          {label}
        </div>
      </div>
      {labelBottomSection}
    </label>
  );
});
