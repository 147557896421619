import { useAccountsForSelect } from '@/hooks/use-accounts-for-select';
import { useCreditAccountContext } from '@/hooks/use-credit-account-context';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { SET_AMOUNT_STEP } from '../constants';
import { paymentAccountSchema } from '../schemas/payment-account-schema';
import { useAdditionPaymentContext } from './use-addition-payment-context';
import { useSelectPaymentAmount } from './use-select-payment-amount';

export function useSelectAccount() {
  const additionPaymentController = useAdditionPaymentContext();
  const creditAccountContext = useCreditAccountContext();
  const controller = useSelectPaymentAmount();
  const accountsForSelect = useAccountsForSelect();

  const form = useForm({
    defaultValues: { paymentAccountID: additionPaymentController.payment.paymentAccountID },
    mode: 'onChange',
    resolver: yupResolver(paymentAccountSchema),
  });

  function onBack() {
    additionPaymentController.wizardController.goToStep(SET_AMOUNT_STEP);
  }

  return {
    form,
    isExternal: accountsForSelect.isExternalAccount(form.watch().paymentAccountID),
    wizard: additionPaymentController.wizardController,
    payment: additionPaymentController.payment,
    isLoading: accountsForSelect.isLoadingExternalAccounts || controller.isLoading,
    verifiedAccountsByMember: accountsForSelect.verifiedAccountsByMember,
    options: accountsForSelect.accountOptions,
    hasPaymentDue: controller.hasPaymentDue,
    nextPaymentDueDate: controller.nextPaymentDueDate,
    id: creditAccountContext.account.id,
    onSubmit: form.handleSubmit(additionPaymentController.setPaymentAccount),
    onBack,
  };
}
