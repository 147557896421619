import { HMBradleyTimeZones } from '@/constants';
import { DateTime } from 'luxon';

export function isTodayDate(date: Date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

const CUTOFF = DateTime.now().setZone(HMBradleyTimeZones.CreditCardPaymentCutOff).set({
  hour: 17,
  minute: 30,
  second: 0,
});

export const IS_AFTER = DateTime.local().toJSDate() > CUTOFF.toJSDate();

export const EARLIEST_PAYMENT_DATE = IS_AFTER
  ? DateTime.local().plus({ days: 1 }).startOf('day')
  : DateTime.local().startOf('day');
