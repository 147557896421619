import { DepositAccountType, useGetDepositAccountsForPaymentQuery } from '@/generated/graphql';
import { useAccountsForSelect } from '@/hooks/use-accounts-for-select';
import { accountNameWithBalanceCents, createHMBradleyAccountNickname } from '@/routes/account/helpers';
import { httpClient } from '@/transports/http';
import { useGetLedgerV4Accounts } from '@core/mainframe-react-query';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { FUTURE_PAYMENT, IMMEDIATE_PAYMENT, SET_ACCOUNT_STEP } from '../constants';
import { useAdditionPaymentContext } from './use-addition-payment-context';
import { useDateResolver } from './use-date-resolver';
import { useSelectPaymentAmount } from './use-select-payment-amount';

export function useSelectDate() {
  const additionPaymentController = useAdditionPaymentContext();
  const controller = useSelectPaymentAmount();
  const accountsForSelect = useAccountsForSelect();

  const getDepositAccountsForPayment = useGetDepositAccountsForPaymentQuery(
    {},
    {
      select(data) {
        const account = data.depositAccounts.find(
          ({ id }) => additionPaymentController.payment.paymentAccountID === id,
        );
        if (!account) return;

        return {
          nycbAccountName: accountNameWithBalanceCents(
            account.accountType === DepositAccountType.Joint ? 'Joint' : 'Deposit',
            account.depositAccountNumber.lastFour,
            account.depositAccountBalances.availableBalanceInCents,
          ),
        };
      },
    },
  );

  const getLedgerV4AccountsQuery = useGetLedgerV4Accounts(httpClient, {
    options: {
      select(data) {
        const account = data.data.find(({ id }) => additionPaymentController.payment.paymentAccountID === id);
        if (!account) return { data };
        const paymentAccountName = createHMBradleyAccountNickname(account.accountType, account.accountNumberLastFour);
        return { data, paymentAccountName };
      },
    },
  });

  const resolver = useDateResolver();
  const form = useForm({
    defaultValues: {
      dateType: controller.hasBalance ? IMMEDIATE_PAYMENT : FUTURE_PAYMENT,
      date: additionPaymentController.payment.date,
    },
    mode: 'onChange',
    resolver,
  });

  function onSubmit(data: { dateType: string; date: string }) {
    const selectedDate =
      data.dateType === IMMEDIATE_PAYMENT
        ? DateTime.now().startOf('day').toFormat('yyyy-MM-dd')
        : DateTime.fromISO(data.date).toFormat('yyyy-MM-dd');
    additionPaymentController.setPaymentDate({ date: selectedDate, dateType: form.watch().dateType });
  }

  function onBack() {
    additionPaymentController.wizardController.goToStep(SET_ACCOUNT_STEP);
  }

  return {
    form,
    wizard: additionPaymentController.wizardController,
    payment: additionPaymentController.payment,
    isLoading:
      accountsForSelect.isLoadingExternalAccounts ||
      controller.isLoading ||
      getLedgerV4AccountsQuery.isLoading ||
      getDepositAccountsForPayment.isLoading,
    hasPaymentDue: controller.hasPaymentDue,
    nextPaymentDueDate: controller.nextPaymentDueDate,
    optionsProps: {
      isTodayPaymentDisabled: !controller.hasBalance,
      isTodayPaymentSelected: form.watch().dateType === IMMEDIATE_PAYMENT,
    },
    isShowDatePicker: FUTURE_PAYMENT === form.watch().dateType,
    accountName:
      accountsForSelect.getExternalAccount(additionPaymentController.payment.paymentAccountID)
        ?.externalAccountNickname ||
      getLedgerV4AccountsQuery.data?.paymentAccountName ||
      getDepositAccountsForPayment.data?.nycbAccountName,
    onSubmit: form.handleSubmit(onSubmit),
    onBack,
  };
}
