import { CompliancePolicyNames } from '@core/mainframe-js-sdk';

export type OfferDetails = {
  requiresHardPull: boolean;
  documents: any;
  expiresOn: string;
  id: string;
  policies: Policy[];
};

export enum PolicyNames {
  AutoPayMinimum = 'AUTOPAYMINIMUM',
  HardPull = 'HARDPULL',
  CardAgreement = 'CARDAGREEMENT',
  AutoPayStatement = 'AUTOPAYSTATEMENT',
  PrivacyNotice = 'MRV_PN',
  PatriotAct = 'PATRIOTACT',
  MrvPii = 'MRV_PII',
  MrvEftRoutines = 'MRV_EFT_ROUTINES',
}

export type Policy = {
  policyID: string;
  consentType: string;
  consentedAt?: string;
  fileURL: string;
  id: string;
  policyName: CompliancePolicyNames;
  policyText: string;
  policyTitle: string;
  policyVersionID: string;
  tags: ['credit'];
  version: string;
};

export type HardPull = {
  policyName: PolicyNames.HardPull;
  policyID: string;
  policyVersionID: string;
  consentType: string;
  consentedAt?: string;
  fileURL?: string;
  id?: string;
  policyText?: string;
  policyTitle?: string;
  tags?: ['credit'];
  version?: string;
};
