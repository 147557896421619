import { SubmitButton } from '@/components/buttons-main/submit-button';
import { ControlledDropdown } from '@/components/form/components/form-inputs/controlled-dropdown';
import { FULLSTORY_UNMASK } from '@/constants';
import { FAQ_AVAILABLE_CREDIT } from '@/constants/app';
import { ACCOUNT_MANAGE_LINKED_ACCOUNTS_CONNECT_PATH } from '@/constants/routes';
import { UTCDateLongFormatter } from '@/i18n/datetime';
import { CurrencyFormatter } from '@/i18n/numbers';
import { BACK_TO_PARAM_NAME } from '@/routes/account/routes/manage/routes/linkedaccounts/routes/connect/helpers';
import { ArrowLongRightIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import Link from 'next/link';
import * as React from 'react';
import { useSelectAccount } from '../hooks/use-select-account';
import { AdditionPaymentSkeleton } from './addition-payment-skeleton';

function Icon(props: { isExternal: boolean; isSelected: boolean }) {
  if (!props.isSelected) {
    return <ArrowLongRightIcon className="w-4" />;
  }
  return props.isExternal ? (
    <XMarkIcon className="w-4 text-grey-dark" />
  ) : (
    <CheckCircleIcon className="w-6 text-white fill-purple" />
  );
}

function SmartRoute(props: { isExternal: boolean; isSelected: boolean }) {
  return (
    <div
      className={clsx('flex flex-col gap-4 p-4 rounded-md bg-white-dark max-w-sm', {
        'border-2 border-purple': props.isExternal,
      })}
    >
      {!props.isSelected ? (
        <h3>Take the smart route by paying with an HMBradley Deposit Account!</h3>
      ) : props.isExternal ? (
        <h3>You’re missing out on the smart route! Pay with an HMBradley Deposit Account</h3>
      ) : (
        <h3>You’re taking the smart route by paying with an HMBradley Deposit Account!</h3>
      )}
      <div className="flex flex-col gap-3 text-sm">
        <div className="flex gap-4 items-center">
          <Icon isExternal={props.isExternal} isSelected={props.isSelected} />
          <p className="flex-1">Payment applied to available credit on the same day</p>
        </div>
        <div className="flex gap-4 items-center">
          <Icon isExternal={props.isExternal} isSelected={props.isSelected} />
          <p className="flex-1">No impact to your Cash Flow calculation</p>
        </div>
      </div>
    </div>
  );
}

export function SelectAccount() {
  const controller = useSelectAccount();

  if (controller.isLoading) {
    return <AdditionPaymentSkeleton />;
  }

  return (
    <div className={clsx('flex justify-center sm:justify-start flex-col gap-3 p-6', FULLSTORY_UNMASK)}>
      <p className="text-lg">Credit Card Payment</p>
      <div className="flex gap-2 text-sm">
        <p className="text-blue uppercase">Statement due date</p>
        <span className="text-right flex-1 pr-16">
          {controller.hasPaymentDue
            ? UTCDateLongFormatter.format(new Date(controller.nextPaymentDueDate))
            : 'No payment is due'}
        </span>
      </div>
      <div className="flex gap-2 text-sm">
        <p className="text-blue uppercase">Payment amount</p>
        <span className="text-right flex-1 pr-16">
          {CurrencyFormatter.format(parseFloat(controller.payment.amount))}
        </span>
      </div>
      <hr />

      <p>Select the account you’ll pay from:</p>
      <SmartRoute isExternal={controller.isExternal} isSelected={Boolean(controller.form.watch().paymentAccountID)} />

      <form className="flex flex-col gap-2" onSubmit={controller.onSubmit}>
        <p className="text-tiny font-light pb-2">
          <a href={FAQ_AVAILABLE_CREDIT} target="_blank" rel="noopener noreferrer" className="text-blue font-medium">
            Learn more here.
          </a>
          &nbsp; Payments cannot be made from Plan accounts.
        </p>
        <ControlledDropdown
          name="paymentAccountID"
          label="Account"
          placeholder="Select account"
          value={controller.options.find((c) => c.value === controller.form.watch()?.paymentAccountID) || null}
          options={controller.verifiedAccountsByMember}
          error={controller.form.formState.errors?.paymentAccountID?.message}
          control={controller.form.control}
        />

        <Link
          href={{
            pathname: ACCOUNT_MANAGE_LINKED_ACCOUNTS_CONNECT_PATH,
            query: { [BACK_TO_PARAM_NAME]: 'creditcardpayments' },
          }}
          className="text-blue text-sm"
        >
          + Link an external account
        </Link>

        <div className="flex flex-col gap-2">
          <SubmitButton
            color="blue"
            className="text-md rounded-md m-0 w-full max-w-full disabled:bg-blue-dark/60"
            label="Next"
            disabled={!controller.form.formState.isValid}
          />

          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent w-full bg-grey-lighter px-4 py-2 text-md font-medium hover:bg-grey-lighter/90 focus:outline-none focus-visible:ring-2 focus-visible:bg-grey-lighter/90 focus-visible:ring-offset-2"
            onClick={controller.onBack}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
}
