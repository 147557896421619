import { Drawer } from '@/components/drawers';
import { OneTimeAdditionPayment } from '@/routes/account/routes/credit/routes/[cardId]/payment/components/card-payment/components/one-time-addition-payment';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as React from 'react';

type AdditionPaymentProps = {
  onDrawerClose: () => void;
  isOpen: boolean;
  title: string;
};

export function OneTimeAdditionPaymentWithSidebar(props: AdditionPaymentProps) {
  return (
    <Drawer onDrawerClose={props.onDrawerClose} open={props.isOpen} anchor="right">
      <Dialog.Panel className="sm:m-2 sm:rounded-lg w-screen sm:w-full h-screen sm:max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
        <Dialog.Title
          as="div"
          className="flex justify-between font-medium leading-6 text-sm text-grey bg-white-dark p-lg"
        >
          <p>{props.title}</p>
          <XMarkIcon className="w-lg cursor-pointer" onClick={props.onDrawerClose} />
        </Dialog.Title>
        <div className="w-screen sm:max-w-md">
          <OneTimeAdditionPayment onClose={props.onDrawerClose} />
        </div>
      </Dialog.Panel>
    </Drawer>
  );
}
