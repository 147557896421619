import { SubmitButton } from '@/components/buttons-main/submit-button';
import { CurrencyField } from '@/components/form/components/form-inputs/currency-field';
import { RadioField } from '@/components/form/components/form-inputs/radio-field';
import { InfoButtonToolTip } from '@/components/info-button-tool-tip';
import { FULLSTORY_UNMASK } from '@/constants';
import { UTCDateLongFormatter } from '@/i18n/datetime';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import * as React from 'react';
import { Controller } from 'react-hook-form';
import { useSelectPaymentAmount } from '../hooks/use-select-payment-amount';
import { AdditionPaymentSkeleton } from './addition-payment-skeleton';

function PaymentAmountTypeTooltip(props: { nextPaymentDueDate?: string; currentCycleDate: string }) {
  return (
    <div className="flex items-center gap-1 self-start">
      <p className="font-medium">Select payment amount:</p>
      <InfoButtonToolTip icon={<InformationCircleIcon className="text-gray-500 w-4 h-4  mt-0.5" />}>
        <div className="flex flex-col bg-black text-white text-sm font-light p-md gap-2 rounded-md">
          <p>
            <b>Remaining statement balance</b> is the amount you owe from your most recent statement dated{' '}
            {UTCDateLongFormatter.format(new Date(props.currentCycleDate))} less any payments or credits to your account
            since then.{' '}
            {props.nextPaymentDueDate && (
              <span>
                This amount is due by {UTCDateLongFormatter.format(new Date(props.nextPaymentDueDate))} to avoid
                incurring interest.
              </span>
            )}
          </p>
          <p>
            <b>Current balance</b> is the total amount you currently owe. Some of this amount may not be due yet.
          </p>
          <p>
            <b>Minimum due</b> is the minimum amount you must pay to avoid going delinquent and incurring a late fee.
            You will incur interest charges on the remaining statement balance if you only pay the minimum due.
          </p>
        </div>
      </InfoButtonToolTip>
    </div>
  );
}

export function SelectPaymentAmount() {
  const controller = useSelectPaymentAmount();

  if (controller.isLoading) {
    return <AdditionPaymentSkeleton />;
  }

  return (
    <div className={clsx('flex justify-center sm:justify-start flex-col gap-4 p-6', FULLSTORY_UNMASK)}>
      <p className="text-lg">Credit Card Payment</p>
      <div className="flex gap-4 text-sm">
        <p className="text-blue uppercase">Statement due date</p>
        <span>
          {controller.hasPaymentDue
            ? UTCDateLongFormatter.format(new Date(controller.nextPaymentDueDate))
            : 'No payment is due'}
        </span>
      </div>
      <hr />
      <PaymentAmountTypeTooltip
        nextPaymentDueDate={controller.nextPaymentDueDate}
        currentCycleDate={controller.currentCycleDate}
      />

      <form onSubmit={controller.onSubmit}>
        <RadioField
          {...controller.form.register('paymentType')}
          options={controller.options}
          error={controller.form.formState.errors?.paymentType?.message}
        />

        {controller.showCustomAmount && (
          <Controller
            render={({ field }) => {
              return (
                <CurrencyField
                  {...field}
                  className="max-w-sm mb-2"
                  label="Custom payment amount"
                  placeholder="Custom payment amount"
                  error={controller.form.formState.errors?.amount?.message}
                />
              );
            }}
            control={controller.form.control}
            name="amount"
          />
        )}

        <p className="text-tiny font-light pb-2 max-w-sm">
          *If you choose to schedule a payment for the Current Balance, the Current Balance amount above may change by
          the scheduled payment date. If your outstanding balance on the scheduled payment date is less than the
          scheduled payment amount, we will only process payment for the amount of such actually outstanding balance as
          of the payment processing date. By clicking "Next" and proceeding, you authorize the processing of payments of
          amounts lower than the scheduled payment amount.
        </p>

        <div className="flex flex-col gap-2">
          <SubmitButton
            color="blue"
            className="text-md rounded-md m-0 w-full max-w-full disabled:bg-blue-dark/60"
            label="Next"
            disabled={!controller.form.formState.isValid}
          />

          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent w-full bg-grey-lighter px-4 py-2 text-md font-medium hover:bg-grey-lighter/90 focus:outline-none focus-visible:ring-2 focus-visible:bg-grey-lighter/90 focus-visible:ring-offset-2"
            onClick={controller.onClose}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
