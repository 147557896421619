import cx from 'clsx';
import * as React from 'react';
import { FormFieldError } from '../form-field-error';

interface FieldsetProps extends React.HTMLProps<HTMLFieldSetElement> {
  legend?: string;
  className?: string;
  error?: string;
}

export function Fieldset(props: FieldsetProps) {
  return (
    <fieldset className={cx('flex flex-col items-start', props?.className)}>
      {props?.legend && (
        <legend className={cx('text-base mb-2', { 'text-red': !!props?.error })}>{props.legend}</legend>
      )}
      {props.children}
      {props?.error && <FormFieldError>{props.error}</FormFieldError>}
    </fieldset>
  );
}
