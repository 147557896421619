import * as React from 'react';
import { Fieldset } from '../fieldset';
import { Radio, RadioProps } from '../radio';

type Value = string | number;

type RadioOption = {
  label: string | React.ReactNode;
  value: Value;
  disabled?: boolean;
};

interface RadioFieldProps extends Omit<RadioProps, 'label'> {
  legend?: string;
  error?: string;
  options: RadioOption[];
  defaultCheckedOption?: Value;
  labelClassName?: string;
  isHasNoDefaultOption?: boolean;
}

export const RadioField = React.forwardRef<HTMLInputElement, RadioFieldProps>(function RadioField(props, ref) {
  const { legend, error, name, options, defaultCheckedOption, isHasNoDefaultOption, className, ...radioProps } = props;
  const [checked, setChecked] = React.useState(isHasNoDefaultOption ? '' : defaultCheckedOption || options[0]?.value);
  const onChange = (e) => {
    radioProps?.onChange(e);
    setChecked(e.target?.value);
  };

  return (
    <Fieldset className={className} legend={legend} error={error}>
      {options.map((option) => (
        <Radio
          key={option.value}
          name={name}
          label={option.label}
          value={option.value}
          disabled={option?.disabled}
          hasError={!!error}
          {...radioProps}
          ref={ref}
          labelClassName="ml-xs pt-px"
          checked={checked === option.value}
          onChange={onChange}
        />
      ))}
    </Fieldset>
  );
});
