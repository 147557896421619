import { toAmountString } from '@/helpers/currency';
import { useActiveCreditAccountContext } from '@/hooks/use-credit-account-context';
import { paymentTypeOptions } from '@/routes/account/routes/credit/routes/[cardId]/payment/components/card-payment/components/one-time-addition-payment/helpers/payment-type-options';
import { httpClient } from '@/transports/http';
import { useGetCardV1CardsBalances } from '@core/mainframe-react-query';
import { useForm } from 'react-hook-form';
import { PAYMENT_TYPE } from '../constants';
import { useAdditionPaymentContext } from './use-addition-payment-context';
import { useGetAmountAndPaymentTypeResolver } from './use-get-amount-and-payment-type-resolver';

export function useSelectPaymentAmount() {
  const activeCreditAccount = useActiveCreditAccountContext();
  const controller = useAdditionPaymentContext();

  const getCardV1CardsBalances = useGetCardV1CardsBalances(httpClient, {
    params: {
      path: { card_id: activeCreditAccount.account.id },
    },
    options: {
      select(data) {
        return {
          balances: data,
          hasPaymentDue: data.nextPaymentDueDate && parseFloat(data.remainingStatementBalance) > 0,
        };
      },
    },
  });

  const options = paymentTypeOptions(
    {
      statementBalance: getCardV1CardsBalances.data?.balances.lastStatementBalance,
      currentBalance: getCardV1CardsBalances.data?.balances.currentBalance,
      minimumDue: getCardV1CardsBalances.data?.balances.minimumDue,
      remainingStatementBalance: getCardV1CardsBalances.data?.balances.remainingStatementBalance,
    },
    getCardV1CardsBalances.data?.balances.currentCycleDate,
    getCardV1CardsBalances.data?.balances.nextPaymentDueDate,
    false,
    getCardV1CardsBalances.data?.balances.totalCycleToDatePayment,
  );

  const resolver = useGetAmountAndPaymentTypeResolver(getCardV1CardsBalances.data?.balances.currentBalance);

  const form = useForm({
    defaultValues: { paymentType: controller.payment.paymentType, amount: controller.payment.amount },
    mode: 'onChange',
    resolver,
  });

  function onSubmit(data: { paymentType: string; amount?: string }) {
    if (data.paymentType === PAYMENT_TYPE.CUSTOM_AMOUNT) {
      controller.setPaymentType({
        paymentType: data.paymentType,
        amount: toAmountString(data.amount as string),
      });
      return;
    }

    const GET_AMOUNT_VALUE = {
      [PAYMENT_TYPE.CURRENT_BALANCE]: getCardV1CardsBalances.data?.balances.currentBalance,
      [PAYMENT_TYPE.MINIMUM]: getCardV1CardsBalances.data?.balances.minimumDue,
      [PAYMENT_TYPE.REMAINING_BALANCE]: getCardV1CardsBalances.data?.balances.remainingStatementBalance,
    };

    controller.setPaymentType({
      paymentType: data.paymentType,
      amount: GET_AMOUNT_VALUE[data.paymentType],
    });
  }

  return {
    form,
    hasPaymentDue: getCardV1CardsBalances.data?.hasPaymentDue,
    options,
    onClose: controller.onClose,
    showCustomAmount: form.watch().paymentType === PAYMENT_TYPE.CUSTOM_AMOUNT,
    hasBalance: Boolean(
      getCardV1CardsBalances.data?.balances.currentBalance &&
        parseFloat(getCardV1CardsBalances.data.balances.currentBalance) > 0,
    ),
    onSubmit: form.handleSubmit(onSubmit),
    isLoading: activeCreditAccount.isLoading || getCardV1CardsBalances.isLoading,
    currentBalance: getCardV1CardsBalances.data?.balances.currentBalance,
    currentCycleDate: getCardV1CardsBalances.data?.balances.currentCycleDate,
    nextPaymentDueDate: getCardV1CardsBalances.data?.balances.nextPaymentDueDate,
    lastStatementBalance: getCardV1CardsBalances.data?.balances.lastStatementBalance,
    minimumDue: getCardV1CardsBalances.data?.balances.minimumDue,
    totalCycleToDatePayment: getCardV1CardsBalances.data?.balances.totalCycleToDatePayment,
    remainingStatementBalance: getCardV1CardsBalances.data?.balances.remainingStatementBalance,
  };
}
