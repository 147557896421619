import * as React from 'react';
import { Resolver } from 'react-hook-form';
import { FUTURE_PAYMENT } from '../constants';

export function useDateResolver(): Resolver<{
  dateType: string;
  date: string;
}> {
  return React.useCallback((values) => {
    if (values.dateType === FUTURE_PAYMENT && !values.date) {
      return {
        values: {},
        errors: {
          date: {
            type: 'required',
            message: 'Date is required',
          },
        },
      };
    }
    return { values, errors: {} };
  }, []);
}
