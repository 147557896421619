export const SET_AMOUNT_STEP = 'SET_AMOUNT_STEP';
export const SET_ACCOUNT_STEP = 'SET_ACCOUNT_STEP';
export const SELECT_DATE_STEP = 'SELECT_DATE_STEP';
export const REVIEW_STEP = 'REVIEW_STEP';

export const IMMEDIATE_PAYMENT = 'IMMEDIATE_PAYMENT';
export const FUTURE_PAYMENT = 'FUTURE_PAYMENT';

export const PAYMENT_TYPE_CURRENT_BALANCE = 'CURRENT_BALANCE';
export const PAYMENT_TYPE_MINIMUM = 'MINIMUM';
export const PAYMENT_TYPE_CUSTOM_AMOUNT = 'CUSTOM_AMOUNT';
export const PAYMENT_TYPE_REMAINING_BALANCE = 'REMAINING_BALANCE';

export const WIZARD_STEPS = [
  { id: SET_AMOUNT_STEP, order: 1 },
  { id: SET_ACCOUNT_STEP, order: 2 },
  { id: SELECT_DATE_STEP, order: 3 },
  { id: REVIEW_STEP, order: 4 },
];

export enum PAYMENT_TYPE {
  CURRENT_BALANCE = 'CURRENT_BALANCE',
  MINIMUM = 'MINIMUM',
  CUSTOM_AMOUNT = 'CUSTOM_AMOUNT',
  REMAINING_BALANCE = 'REMAINING_BALANCE',
}
