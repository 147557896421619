import { WizardStep } from '@/components/wizard';
import * as React from 'react';
import { AdditionPaymentProvider } from './components/addition-payment-provider';
import { ReviewAndSubmit } from './components/review-and-submit';
import { SelectAccount } from './components/select-account';
import { SelectDate } from './components/select-date';
import { SelectPaymentAmount } from './components/select-payment-amount';
import { REVIEW_STEP, SELECT_DATE_STEP, SET_ACCOUNT_STEP, SET_AMOUNT_STEP } from './constants';

type AdditionPaymentProps = {
  onClose: () => void;
};

export function OneTimeAdditionPayment(props: AdditionPaymentProps) {
  return (
    <AdditionPaymentProvider onClose={props.onClose}>
      <WizardStep step={SET_AMOUNT_STEP} component={SelectPaymentAmount} />
      <WizardStep step={SET_ACCOUNT_STEP} component={SelectAccount} />
      <WizardStep step={SELECT_DATE_STEP} component={SelectDate} />
      <WizardStep step={REVIEW_STEP} component={ReviewAndSubmit} />
    </AdditionPaymentProvider>
  );
}
