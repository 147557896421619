import {
  ScheduleCreditPaymentMutationVariables,
  useGetSubmitPaymentAccountsQuery,
  useInitiateCreditPaymentMutation,
  useScheduleCreditPaymentMutation,
} from '@/generated/graphql';
import { toAmountInCents } from '@/helpers/currency';
import { getErrorMessage } from '@/helpers/error-handling';
import { useAccountsForSelect } from '@/hooks/use-accounts-for-select';
import { useActiveCreditAccountContext } from '@/hooks/use-credit-account-context';
import {
  useGetCardV1CardsBalancesQueryKey,
  useGetCardV1PayQueryKey,
  useGetCardV1ScheduledPaymentsQueryKey,
} from '@core/mainframe-react-query';
import { useQueryClient } from 'react-query';
import { FUTURE_PAYMENT, SELECT_DATE_STEP } from '../constants';
import { useAdditionPaymentContext } from './use-addition-payment-context';
import { useSelectDate } from './use-select-date';
import { useSelectPaymentAmount } from './use-select-payment-amount';

export function useReviewAndSubmit() {
  const queryClient = useQueryClient();
  const additionPaymentController = useAdditionPaymentContext();
  const activeCreditAccount = useActiveCreditAccountContext();
  const controller = useSelectPaymentAmount();
  const accountsForSelect = useAccountsForSelect();
  const dateController = useSelectDate();
  const cardV1PayQueryKey = useGetCardV1PayQueryKey();
  const cardV1ScheduledPaymentsQueryKey = useGetCardV1ScheduledPaymentsQueryKey();
  const cardV1CardsBalancesQueryKey = useGetCardV1CardsBalancesQueryKey();
  const isExternal = accountsForSelect.isExternalAccount(additionPaymentController.payment.paymentAccountID);
  const initiateCreditTransferMutation = useInitiateCreditPaymentMutation({
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(cardV1CardsBalancesQueryKey),
        queryClient.invalidateQueries(cardV1PayQueryKey),
      ]);
    },
  });
  const scheduleCreditPaymentMutation = useScheduleCreditPaymentMutation({
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries(cardV1ScheduledPaymentsQueryKey)]);
    },
  });
  const getSubmitPaymentAccountsQuery = useGetSubmitPaymentAccountsQuery({});

  function onSubmit() {
    const isFuture = Date.parse(additionPaymentController.payment.date) > Date.now();

    const paymentData: ScheduleCreditPaymentMutationVariables = {
      cardId: activeCreditAccount.account.id,
      amountInCents: toAmountInCents(additionPaymentController.payment.amount),
      scheduledDate: isFuture ? additionPaymentController.payment.date : undefined,
      externalAccountId: undefined,
      depositAccountId: undefined,
      ledgerId: undefined,
      paymentAccountLastFour: undefined,
    };

    const nycbAccount = getSubmitPaymentAccountsQuery.data?.depositAccounts.find((a) => {
      return a.id === additionPaymentController.payment.paymentAccountID;
    });

    if (isExternal) {
      paymentData.externalAccountId = additionPaymentController.payment.paymentAccountID;
    } else if (nycbAccount) {
      paymentData.depositAccountId = additionPaymentController.payment.paymentAccountID;
      paymentData.paymentAccountLastFour = nycbAccount.depositAccountNumber.lastFour;
    } else {
      paymentData.ledgerId = additionPaymentController.payment.paymentAccountID;
    }

    if (isFuture) {
      scheduleCreditPaymentMutation.mutate(paymentData);
    } else {
      initiateCreditTransferMutation.mutate(paymentData);
    }
  }

  function onBack() {
    additionPaymentController.wizardController.goToStep(SELECT_DATE_STEP);
  }

  return {
    onClose: additionPaymentController.onClose,
    payment: additionPaymentController.payment,
    errorMessage:
      getErrorMessage(scheduleCreditPaymentMutation.error) || getErrorMessage(initiateCreditTransferMutation.error),
    isError: scheduleCreditPaymentMutation.isError || initiateCreditTransferMutation.isError,
    isSuccess: scheduleCreditPaymentMutation.isSuccess || initiateCreditTransferMutation.isSuccess,
    isSubmitting: scheduleCreditPaymentMutation.isLoading || initiateCreditTransferMutation.isLoading,
    isLoadingAccounts: accountsForSelect.isLoadingExternalAccounts || controller.isLoading,
    accountName: dateController.accountName,
    isFuturePayment: additionPaymentController.payment.dateType === FUTURE_PAYMENT,
    isExternal,
    onMakeAnotherPayment: additionPaymentController.makeAnotherPayment,
    onSubmit,
    onBack,
  };
}
