import { Wizard } from '@/components/wizard';
import { SET_AMOUNT_STEP, WIZARD_STEPS } from '../constants';
import { AdditionPaymentContext } from '../contexts/addition-payment-context';
import { useAdditionPayment } from '../hooks/use-addition-payment';

type AdditionPaymentProps = {
  onClose: () => void;
};

function AdditionPayment(props: React.PropsWithChildren<AdditionPaymentProps>) {
  const controller = useAdditionPayment({ onClose: props.onClose });

  return <AdditionPaymentContext.Provider value={controller}>{props.children}</AdditionPaymentContext.Provider>;
}

export function AdditionPaymentProvider(props: React.PropsWithChildren<AdditionPaymentProps>) {
  return (
    <Wizard steps={WIZARD_STEPS} initialStep={SET_AMOUNT_STEP}>
      <AdditionPayment {...props} />
    </Wizard>
  );
}
