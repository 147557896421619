import { PolicyNames } from '@/routes/account/routes/one-click-credit/types';

export enum AutoPayAmount {
  REMAINING_STATEMENT_BALANCE = 'remaining_statement_balance',
  MINIMUM_PAYMENT = 'minimum_payment',
}

const autoPayPolicyMap = {
  [AutoPayAmount.REMAINING_STATEMENT_BALANCE]: PolicyNames.AutoPayStatement,
  [AutoPayAmount.MINIMUM_PAYMENT]: PolicyNames.AutoPayMinimum,
};

export const PAYMENT_LABELS = {
  [AutoPayAmount.REMAINING_STATEMENT_BALANCE]: 'remaining statement balance',
  [AutoPayAmount.MINIMUM_PAYMENT]: 'minimum payment',
};

export const PAYMENT_OPTIONS = [
  {
    value: AutoPayAmount.REMAINING_STATEMENT_BALANCE,
    label: 'Remaining statement balance',
  },
  {
    value: AutoPayAmount.MINIMUM_PAYMENT,
    label: 'Minimum payment',
  },
];

export function isAutoPayPolicy(autoPayAmount: AutoPayAmount, policyName: string) {
  return autoPayPolicyMap[autoPayAmount] === policyName;
}
