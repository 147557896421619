import { UTCDateLongFormatter } from '@/i18n/datetime';
import cx from 'clsx';
import * as React from 'react';

const STATEMENT_BALANCE_TESTID = 'statement-balance-info';
const CURRENT_BALANCE_TESTID = 'current-balance-info';
const MINIMUM_DUE_TESTID = 'minimum-due-info';

type StatementBalanceProps = {
  currentCycleDate?: string;
  dueDate?: string;
  isDisabled?: boolean;
};

export function StatementBalance(props: StatementBalanceProps) {
  const { currentCycleDate = '', dueDate = '' } = props;
  return (
    <div
      data-testid={STATEMENT_BALANCE_TESTID}
      className={cx('max-w-sm text-sm text-grey-dark md:text-white font-light', { 'opacity-60': props?.isDisabled })}
    >
      <h6 className="inline-block font-medium">Remaining statement balance</h6>{' '}
      <span>
        is the amount you owe from your most recent statement dated{' '}
        {UTCDateLongFormatter.format(new Date(currentCycleDate))} less any payments or credits to your account since
        then.
      </span>
      {dueDate && (
        <span>
          {' '}
          This amount is due by {UTCDateLongFormatter.format(new Date(dueDate))} to avoid incurring interest.
        </span>
      )}
    </div>
  );
}

type CurrentBalanceProps = {
  isDisabled?: boolean;
};

export function CurrentBalance(props: CurrentBalanceProps) {
  return (
    <div
      data-testid={CURRENT_BALANCE_TESTID}
      className={cx('max-w-sm text-sm text-grey-dark md:text-white font-light', { 'opacity-60': props?.isDisabled })}
    >
      <h6 className="inline-block font-medium">Current balance</h6>{' '}
      <span>is the total amount you currently owe. Some of this amount may not be due yet.</span>
    </div>
  );
}

type MinimumDueProps = {
  isDisabled?: boolean;
};

export function MinimumDue(props: MinimumDueProps) {
  return (
    <div
      data-testid={MINIMUM_DUE_TESTID}
      className={cx('max-w-sm text-sm text-grey-dark md:text-white font-light', { 'opacity-60': props?.isDisabled })}
    >
      <h6 className="inline-block font-medium">Minimum due</h6>{' '}
      <span>
        is the amount you must pay to avoid going delinquent and incurring a late fee. You will incur interest charges
        on the remaining statement balance if you only pay the minimum due.
      </span>
    </div>
  );
}
